:root {
  --bs-color-pink: #cab8ff;
  --bs-color-green: #c1ffd7;
  --bs-color-splitter: #414c5c;
  --bs-color-error: #e98d9f;
  --bs-color-link: #e63fff;
  --bs-color-blur-bg: #1e2024b5;
}

body {
  background-color: #253143;
}

.w-0 {
  width: 0;
}

.ml-auto {
  margin-left: auto;
}

.mr-0 {
  margin-left: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.text-right {
  text-align: right;
}

.cursor-pointer {
  cursor: pointer;
}

a,
button {
  transition: 0.5s all ease;
}

.header {
  z-index: 100;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  backdrop-filter: blur(20px);
  background: var(--bs-color-blur-bg);
  border-bottom: 1px solid var(--bs-color-splitter);
}

.mobileHeader {
  display: none;
}

.maintain-header {
  z-index: 100;
  position: fixed;
  left: 0;
  right: 0;
  top: 61px;
  backdrop-filter: blur(20px);
  background: #ffb8d0e2;
  border-bottom: 1px solid var(--bs-color-splitter);
}

.sidenavmenu {
  width: 100%;
}

.removesidenavmenu {
  width: 0;
}

nav.navbar.navbar-expand-md.navbar-light.bg-light {
  display: flex;
}

.banner {
  background-image: url(../images/Group_578.png), url(../images/bg_bent.svg);
  background-color: #10151d;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 120px;
  padding-bottom: 120px;
}

.header nav.navbar.navbar-expand-md {
  justify-content: space-between;
}

ul.primaryMenu {
  border-left: 1.5px solid var(--bs-color-splitter);
  display: flex;
  margin-left: 50px;
  margin-bottom: 0;
  padding: 0px;
  list-style: none;
}

ul.primaryMenu li {
  margin: 10px 25px;
}

ul.primaryMenu li a {
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
}

ul.primaryMenu li a:hover {
  color: #c1ffd7;
}

.theme-icon {
  width: 40px;
  height: 40px;
  margin-right: 20px;
  cursor: pointer;
}

a.navbar-brand:hover {
  opacity: 0.5;
}

.bannerboxes {
  padding-right: 12px;
  margin: 0 auto 40px;
}

.boxwrap {
  display: flex;
  width: 100%;
  padding: 25px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 10px 10px 0px 0px #b5deff;
  position: relative;
  overflow: hidden;
}

.boxwrap.second {
  box-shadow: 10px 10px 0px 0px #cab8ff;
}

.boxwrap.third {
  box-shadow: 10px 10px 0px 0px #afe3c1;
}

.boxwrap p {
  color: #10151d;
  opacity: 0.7;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2px;
  margin: 0;
}

.boxwrap h2 {
  letter-spacing: 3px;
  color: #10151d;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 57px;
  margin: 0;
}

.boxwrap h2 b {
  font-weight: bold;
  font-size: 48px;
  line-height: 57px;
}

.boxwrap h2 span {
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 3px;
}

.boxwrap.smallone {
  box-shadow: 10px 15px 0px 0px #9edab3;
  padding: 16px 25px;
}

.boxwrap.smalltwo {
  box-shadow: 10px 15px 0px 0px #b9c7dd;
  padding: 16px 25px;
}

.boxwrap.smallone p,
.boxwrap.smalltwo p {
  margin-bottom: 0px;
}

.boxwrap.smallone h2 b,
.boxwrap.smalltwo h2 b {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
}

.boxwrap.smallone h2,
.boxwrap.smalltwo h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.3px;
}

.boxwrap.smallone h2 span,
.boxwrap.smalltwo h2 span {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 3px;
}

.contentSection {
  background: url(../images/Group_578.png);
  background-repeat: repeat;
  background-size: contain;
  background-color: #253143;
  padding-bottom: 90px;
}

.tableTitle p {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}

.tableTitle b {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}

.convert-tableTitle {
  background: #b5deff;
  border-radius: 8px;
  padding: 10px 15px;
}

.convert-table {
  border: 3px solid #10151d;
  border-radius: 8px;
  background: #b5deff;
  box-shadow: 10px 15px 0px 0px #10151d;
}

h2.white {
  color: #fff;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: -0.005em;
  text-align: left;
  margin-bottom: 15px;
}

.converttabs a.active.nav-link {
  cursor: pointer;
  background: transparent;
  border: none;
  color: #10151d;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.24px;
}

.converttabs a.nav-link {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.24px;
  color: #9ba2ac;
  cursor: pointer;
}

.converttabs {
  padding: 10px 20px 20px;
}

.converttabs .card.card-body {
  padding: 1rem 0;
  border: none;
}

.converttabs ul.nav.nav-tabs {
  border-bottom: none;
}

.converttabs p.card-text {
  margin-top: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #10151d;
}

.converttabs p.card-text i {
  opacity: 0.8;
}

.convert-up {
  margin-top: -100px;
}

.section .convert-up {
  margin-top: 0px;
}

.section {
  margin-top: 70px;
}

.infoWrap p {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.24px;
  color: #10151d;
}

.contract-address,
.infoWrap a {
  color: var(--bs-color-link);
  text-decoration: none;
  font-style: normal;
}

.contract-address:hover,
.infoWrap a:hover {
  color: var(--bs-color-link);
  opacity: 0.8;
}

h2.section-header.white {
  color: white;
}

h2.section-header {
  padding-left: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: -0.005em;
  color: #10151d;
  margin-bottom: 15px;
}

.tokentable.table {
  background: #b5deff;
  vertical-align: middle;
  border: 3px solid #10151d;
  border-radius: 20px;
  border-collapse: separate;
  border-spacing: 0;
  box-shadow: 10px 15px 0px 0px #10151d;
}

.tokentable.table.sortable .thead .col {
  cursor: pointer;
}

.tokentable.table.sortable .thead .col .fa.fa-caret-down {
  transition: transform 0.3s;
}

.tokentable.table.sortable .thead .col.asc .fa.fa-caret-down {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}

table.tokentable.table {
  max-width: 1050px;
  margin-right: 0;
  width: 1050px;
  border: none;
  box-shadow: none;
  margin-bottom: 0px;
}

table.tokentable.table thead th {
  line-height: 50px;
  padding: 0.5rem 1.5rem;
  font-size: 12px;
  letter-spacing: -0.24px;
}

table.tokentable.table thead th i.fa.fa-caret-down {
  padding-left: 15px;
  font-size: 20px;
  line-height: 20px;
}

table.tokentable.table thead th:first-child {
  border-top-left-radius: 8px;
}

table.tokentable.table thead th:last-child {
  border-top-right-radius: 8px;
}

/* table.tokentable.table tbody {
    background: #fff;
} */

table.tokentable.table tbody tr:first-child td:first-child {
  border-top-left-radius: 8px;
}

table.tokentable.table tbody tr:first-child td:last-child {
  border-top-right-radius: 8px;
}

table.tokentable.table tbody tr:first-child td {
  border-top: 3px solid #10151d;
}

table.tokentable.table p {
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 14px;
  padding-top: 8px;
}

table.tokentable.table td {
  border: none;
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.24px;
  color: #10151d;
  position: relative;
}

table.tokentable.table td span {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
}

.section table.tokentable.table th {
  border-bottom: none;
}

.Dark .convert-table {
  box-shadow: 10px 15px 0px 0px #607390;
  border: none;
}

.Dark .tokentable {
  border: 3px solid #414c5c;
  box-shadow: 10px 15px 0px 0px #607390;
}

.tokentable.table .thead {
  margin: 0;
  background: #b5deff;
  border-radius: 20px;
  padding: 10px 15px;
  overflow: hidden;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  height: 70px;
  line-height: 14px;
  letter-spacing: -0.005em;
}

.tokentable.table .thead .small {
  font-weight: 400;
  font-size: 12px;
}

.tokentable.table .thead b .small {
  font-size: 14px;
  font-weight: 500;
}

.tokentable.table b {
  font-weight: 500;
}

.tokentable.table .card {
  border-radius: 16px;
}

.tokentable.table .card-body {
  padding: 0;
}

.tokentable.table .card-body .innerWrap {
  border: 2px solid transparent;
  /* transition: border-color .2s; */
}

.tokentable.table .card-body .bentInner {
  width: 100%;
  background: transparent;
  border: none;
  text-align: left;
  color: #10151d;
  margin-bottom: 0 !important;
  padding: 10px;
}

.Dark .tokentable.table .card-body .bentInner {
  color: white;
}

.bentInner .fa-caret-down {
  transition: transform 0.5s;
}

.bentInner.open .fa-caret-down {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}

.bentInner .small {
  font-size: 0.8rem;
}

.tokentable.table .card-body .nav-link {
  cursor: pointer;
  color: #6e7889;
  background: unset;
  border: unset;
}

.tokentable.table .card-body .nav-item:first-child .nav-link {
  padding-left: 0;
}

.tokentable.table .card-body .nav-link.active {
  font-weight: bold;
  color: white;
  /* background: unset; */
  border: unset;
}

.text-center.btnwrap {
  padding: 10px 0;
}

tr:hover {
  background: #cab8ff;
}

.tvlText i.fa.fa-caret-down {
  position: absolute;
  right: 30px;
  font-size: 20px;
}

.splitter-horizontal {
  border-top: 1px solid var(--bs-color-splitter) !important;
}

.bent-rewards-container {
  margin-top: 10px;
  margin-right: auto;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-row-gap: 28px;
  grid-column-gap: 28px;
}

.Dark .stake-bent input {
  color: white;
}

.bentcvx-claim-item {
  display: flex;
}

.bentcvx-claim-item img {
  width: 28px;
  height: 28px;
  padding: 0;
  margin-top: 5px;
}

.bentCVX .bent-rewards-item {
  display: block;
}

.bentCVX .bent-rewards-item img {
  width: auto;
  height: 20px;
  margin: 0;
}

.apr {
  color: #c1ffd7;
}

.bentcvx-claim-item p.rewards-token {
  font-weight: normal;
  padding-left: 10px;
  margin-bottom: 0;
}

.stake-bent.claim .bent-rewards-item {
  width: 350px;
}

.stake-bent.claim .bent-rewards-item .claim-reward {
  margin-left: auto;
  margin-right: 100px;
}

.Dark .bent-rewards-item h4 {
  color: white;
}

.Dark .stake-bent.container a.active.nav-link {
  color: white;
}

footer {
  background: #10151d;
  padding: 30px 0;
}

.footer-inner-block p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #fafafa;
  opacity: 0.5;
  margin-top: 20px;
}

.footer-inner-block h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.24px;
  color: #fafafa;
  margin-bottom: 24px;
}

.footer-inner-block ul {
  list-style: none;
  padding: 0;
}

.footer-inner-block ul li {
  margin-bottom: 12px;
}

.footer-inner-block a {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #fafafa;
  opacity: 0.7;
  text-decoration: none;
}

.footer-inner-block ul li a i.fa {
  font-size: 18px;
  color: #c1ffd7;
  opacity: 1;
}

.footer-inner-block a:hover {
  color: #fafafa;
  opacity: 0.5;
}

p.copyright {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 20px;
  color: #fafafa;
  opacity: 0.4;
  margin-bottom: 0;
}

.divider-left {
  min-width: 1px;
  padding-left: 30px;
  padding-right: 30px;
  border-left: 2px solid #414c5c;
}

.converttabs .divider-left {
  padding-left: 30px;
  padding-right: 30px;
}

.converttabs .inverse {
  padding-right: 30px;
}

.labeltext {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.labeltext label {
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: -0.24px;
  color: #10151d;
}

.amountinput input {
  padding-left: 50px;
  background-repeat: no-repeat;
  background-position: 6px center;
  border: 3px solid #414c5c;
  border-radius: 20px;
}

.amountinput img.inputlogo {
  z-index: 1;
  position: absolute;
  left: 8px;
  top: 7px;
  width: 28px;
}

.amountinput img.inputlogo-second {
  position: absolute;
  left: 22px;
  top: 7px;
  width: 28px;
}

.amountinput {
  position: relative;
}

.lineup {
  width: 65%;
  margin-left: auto;
  margin-right: auto;
  background: #9ba2ac;
  height: 3px;
  position: relative;
}

.btnouter {
  margin-top: 25px;
}

.lineup::before {
  content: '1';
  background: #9ba2ac;
  top: -8px;
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 100px;
  color: #fff;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.24px;
  padding: 1px;
  left: -5px;
}

.lineup::after {
  content: '2';
  background: #9ba2ac;
  top: -8px;
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 100px;
  color: #fff;
  text-align: center;
  right: -5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.24px;
  padding: 1px;
}

.convert-up.cvxSection {
  margin-top: 120px;
}

.bent-details .tooltip.show {
  opacity: 1;
}

.bent-details .tooltip-inner {
  font-size: 12px;
  line-height: 30px;
  padding: 0;
  border: 1px solid #4e5769;
  text-align: left;
  border-radius: 8px;
  overflow: hidden;
}

.bent-details .bs-tooltip-auto[data-popper-placement^='bottom'] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #353c49;
}

.bent-details .bs-tooltip-auto[data-popper-placement^='top'] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #353c49;
}

.bent-details .green-color {
  color: #3fee7c;
}

.tooltip-inner {
  font-family: 'Rubik';
  width: fit-content;
  max-width: 300px !important;
  background: #353c49a0 !important;
  backdrop-filter: blur(20px);
  border: 1px solid #4e5769;
  border-radius: 8px;
}

.text-underline {
  text-decoration: underline;
}

/* .Dark .tokentable.table .card-body .bentInner:hover,
.Dark .tokentable.table .card-body .bentInner.open,
.Dark .tokentable.table .card-body .bentInner:hover .imgText h4,
.Dark .tokentable.table .card-body .bentInner.open .imgText h4,
.Dark .tokentable.table .innerWrap.open .card-text,
.Dark .tokentable.table .innerWrap.open .labeltext label,
.Dark .tokentable.table .innerWrap.open p,
.Dark .tokentable.table .innerWrap.open .imgText h4,
.Dark .tokentable.table .innerWrap.open b{
    color: #10151D;
} */
.Dark .boxwrap h2,
.Dark .boxwrap p,
.Dark .converttabs a.active.nav-link,
.Dark .converttabs p.card-text,
.Dark .converttabs label,
.Dark h2.section-header,
.Dark table.tokentable.table td,
.Dark .currentText h3,
.Dark .currentText p,
.Dark li.addreesAdd,
.Dark .tableText h4,
.Dark .tableText b,
.Dark .infoWrap p,
.Dark .innerWrap .imgText h4,
.Dark .innerWrapother,
.Dark p.epsText,
.Dark .innerAccordian {
  color: #fff;
}

.Dark .boxwrap,
.Dark .card,
.Dark .convert-table {
  background: #18202c;
}

.Dark table.tokentable.table tr:hover td {
  color: #10151d;
}

.Dark .amountinput input {
  border: 3px solid #606f85;
  background-color: transparent;
  color: white;
}

.Dark table.tokentable.table tbody tr:first-child td {
  border-top: 3px solid #414c5c;
}

.Dark table.tokentable.table tbody {
  background: #18202c;
  color: #fff;
}

.Dark .delegateBox {
  background: #b5deff;
  border-radius: 8px;
}

.Dark .currentLock {
  background: #374559;
  border: 3px solid #607390;
  box-shadow: 10px 15px 0px 0px #607390;
}

.Dark .delegateBox .convert-table,
.Dark .lockInner .convert-table {
  background: #b5deff;
}

.Dark .convert-up .convert-table {
  background: #b5deff;
}

.Dark .convert-tableTitle {
  background: #b5deff;
  border-radius: 8px 8px 0 0;
}

.Dark .claimText {
  background: #253143;
}

.Dark .innerWrapother {
  background-color: #18202c;
}

.Dark .cliamBlockOne {
  border: 3px solid #607390;
  box-shadow: 10px 15px 0px 0px #607390;
}

.Dark .bentpoolText {
  border: 3px solid #607390;
}

.primaryMenu .dropdown-toggle {
  background-color: transparent !important;
  border: 0px;
  padding: 0px;
  color: #8d99ab !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
  margin-top: -5px;
}

.primaryMenu .dropdown-toggle:hover {
  background-color: transparent !important;
  border: 0px;
}

.primaryMenu .dropdown-item:hover {
  background-color: transparent !important;
}

/* Dark Theme Css End */
/* Claim page css start */

.bannerBlockWrap {
  margin-left: auto;
  margin-right: auto;
}

.bannerBlockWrap .boxwrap .bannerIcon {
  width: 56px;
}

.bannerBlockWrap .boxwrap h2 {
  letter-spacing: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 66px;
}

.boxwrap img.cardCoin {
  position: absolute;
  right: -40px;
  bottom: -36px;
}

.boxwrap img.dollorCoin {
  position: absolute;
  right: -62px;
  bottom: -40px;
}

.autoText .advance-btn .slider:before {
  background-color: #10151d;
  border: 3px solid #9ba2ac;
}

.autoText .advance-btn input:checked + .slider {
  background-color: #c1ffd7;
}

.autoText .advance-btn input:checked + .slider::before {
  border: 3px solid #c1ffd7;
}

.autoText {
  margin: 90px auto 30px auto;
  text-align: center;
}

.autoText span.textadvance {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.24px;
  color: #ffffff;
  bottom: 6px;
}

/* The container */
.contain {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  user-select: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.24px;
  color: #8d99ab;
  margin-right: 15px;
}

/* Hide the browser's default radio button */
.contain input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.numberCheck .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #10151d;
  border-radius: 50%;
  border: 2px solid #8d99ab;
}

/* On mouse-over, add a grey background color */
.contain:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.contain input:checked ~ .checkmark {
  background-color: #10151d;
  border: 2px solid #c1ffd7;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.contain input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.contain .checkmark:after {
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #c1ffd7;
}

.aproveCheckbox h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: -0.24px;
  color: #ffffff;
  margin-bottom: 24px;
}

.numberCheck {
  margin-bottom: 50px;
}

span.count {
  width: 20px;
  height: 20px;
  display: inline-block;
  border: 1.5px solid #343d4c;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.24px;
  color: #fafafa;
  text-align: center;
  border-radius: 100px;
  padding: 1px;
  background: #242b37;
  position: relative;
}

.bannerBtns {
  position: relative;
}

.bannerBtns::before {
  content: '';
  position: absolute;
  height: 120px;
  background: #242b37;
  width: 2px;
  top: 20px;
  left: 8px;
}

.cliamBlockOne {
  background: #b5deff;
}

.imgText {
  display: flex;
  align-items: center;
  align-content: center;
}

.imgText h2 {
  display: inline-block;
  margin-bottom: 0px !important;
  vertical-align: middle;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.24px;
  color: #10151d;
  padding-left: 10px;
}

.imgText h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.24px;
  display: inline-block;
  padding-left: 10px;
  margin-bottom: 0px;
}

.imgText p {
  margin-bottom: 0;
}

.cliamBlockOne {
  border: 3px solid #10151d;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 10px 15px 0px 0px #10151d;
  margin-bottom: 40px;
}

.earnValue .fa-caret-down {
  position: absolute;
  right: 50px;
}

.earnValue {
  position: relative;
}

.cliamBlockOne .card {
  box-sizing: border-box;
  border-radius: 8px;
}

.cliamBlockOne .card-body {
  padding: 0px;
}

.earnValue p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 0;
}

.checkall-container {
  position: absolute;
  top: 20px;
  right: 110px;
}

.collapseBtn {
  height: 38px;
  position: relative;
}

.collapseBtn i.fa.fa-caret-down {
  text-align: right;
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 20px;
}

.innerAccordian .card {
  background: unset;
  padding: 16px 12px;
}

.depositText {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}

.tokentable.table .card .card-body .innerWrap:hover,
.tokentable.table .card .card-body .innerWrap.open {
  transition: background-color 0.2s;
  background: linear-gradient(102.05deg, #253143b5 -0.64%, #00000000 98.13%);
  border: 2px solid #303f54;
  overflow: hidden;
  border-radius: 16px !important;
}

.innerWrap .card {
  border-top: none;
}

.claimText {
  background: #cab8ff;
  display: inline-block;
  width: 100%;
}

.cliamBox {
  position: relative;
  margin-top: -120px;
  margin-bottom: 80px;
}

.innerAccordian p {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
}

.innerWrapother {
  background-color: #fafafa;
  padding: 16px 12px;
}

.bentpoolText {
  border: 3px solid #10151d;
  border-right: none;
  border-left: none;
  border-radius: 8px;
}

.cliamBox .cliamBlockOne:last-child {
  margin-bottom: 0px;
}

#togglerFifth .clmBtn .fa-caret-down {
  position: absolute;
  right: 20px;
  top: -10px;
  font-size: 26px;
}

.textlayer {
  text-align: center;
  position: relative;
  z-index: 1;
}

.textlayer h4 {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.24px;
  color: #fafafa;
}

.textlayer h6 {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #e5e7ea;
}

.imgOverText {
  background: #9ba2ac;
  border-radius: 12px;
  padding: 31px 0;
  margin-bottom: 24px;
  position: relative;
}

.imgOverText img.starIcon {
  position: absolute;
  left: 45%;
  transform: translate(-50%, -50%);
  top: 50%;
}

.imgOverText.Active h4,
.imgOverText.Active h6 {
  color: #10151d;
}

button#togglerFifth {
  padding: 19px 15px;
}

.imgOverText.Active {
  background: #c1ffd7;
  border: 2px solid #10151d;
  box-shadow: 4px 4px 0 #10151d;
}

.imgOverText.Active img.starIcon {
  position: absolute;
  left: 45%;
  transform: translate(-5%, -50%);
  top: 50%;
}

p.epsText {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #10151d;
}

.cliamBlockOne.LastBlock .innerWrapother {
  padding-bottom: 0px;
}

/* Claim page css End */
/* Lock Page Css start */

.tabInner {
  display: flex;
  background: #242b37;
  border: 1.5px solid #252a38;
  box-shadow: inset 2px 4px 13px #10151d;
  border-radius: 8px;
  padding: 2px 2px 4px;
}

.InnerLock {
  margin-top: -120px;
}

.currentLock {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  border: 3px solid #10150d;
  box-shadow: 10px 15px 0px 0px #10151d;
  margin-bottom: 30px;
}

.currentText p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #10151d;
  opacity: 0.7;
}

.currentText h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.24px;
  color: #10151d;
}

.currentText {
  padding-left: 15px;
}

.currentImage img {
  margin-top: 12px;
}

li.addreesAdd {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.24px;
  color: #10151d;
  margin-right: 25px;
}

li.bentTeam {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.24px;
  color: #9ba2ac;
}

.converttabs ul {
  list-style: none;
  padding: 0;
  display: flex;
  margin-bottom: 25px;
}

.converttabs label {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: -0.24px;
  color: #10151d;
}

.delegateBox .converttabs {
  padding: 20px 20px 37px;
}

.tableText {
  margin: 20px 0;
}

.tableText h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.24px;
  color: #10151d;
  margin: 0px;
}

.tableText h4 img {
  margin-right: 10px;
}

.tableText b span {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 2px;
  font-feature-settings: 'ss01';
}

.tableText b {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.24px;
  color: #10151d;
}

.processWrap {
  background: #10151d;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #10151d;
}

.processWrap span {
  background: #cab8ff;
  border-radius: 4px;
  padding: 4px 5px;
  width: 100%;
  display: inline-block;
}

span.eightySeven {
  width: 87%;
}

span.nintynine {
  width: 99%;
}

.InnerLock .convert-tableTitle {
  padding: 14px 15px;
}

/* Lock Page Css End */

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1170px !important;
  }
}

@media only screen and (max-width: 1199px) {
  .imgOverText.Active img.starIcon {
    left: 30%;
  }

  li.addreesAdd {
    font-size: 14px;
    margin-right: 9px;
  }
}

@media only screen and (max-width: 991px) {
  .imgOverText.Active img.starIcon {
    left: 0;
  }

  .imgText {
    display: flex;
    align-items: center;
    align-content: center;
  }

  .currentLock {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 800px) {
  .imgText h2 {
    font-size: 16px;
    line-height: 18px;
  }
}

@media only screen and (max-width: 767px) {
  ul.primaryMenu {
    display: none;
  }

  .mobileHeader {
    display: inline-block;
  }

  .mobileHeader .dropdown-toggle {
    background-color: transparent !important;
    border: 0px !important;
    padding: 0px;
    color: #8d99ab !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;
    margin-top: -5px;
  }

  .mobileHeader .dropdown {
    padding: 8px 8px 8px 32px;
  }

  .mobileHeader .dropdown-toggle:hover {
    background-color: transparent !important;
    border: 0px !important;
  }

  .btn-secondary.dropdown-toggle:focus {
    box-shadow: none !important;
  }

  .mobileHeader .dropdown-item:hover {
    background-color: transparent !important;
  }

  .table-Wrapper {
    width: 100%;
    overflow: auto;
  }

  .table-Responsive {
    box-shadow: 10px 15px 0px 0px #10151d;
    border: 3px solid #10151d;
    border-radius: 8px;
    margin-right: 12px;
  }

  .convert-table {
    margin-right: 12px;
  }

  .boxwrap h2,
  .bannerBlockWrap .boxwrap h2 {
    font-size: 26px;
    line-height: 31px;
    letter-spacing: 0;
  }

  .boxwrap h2 span {
    letter-spacing: 0;
    font-size: 14.5339px;
    line-height: 17px;
  }

  .boxwrap.smallone {
    box-shadow: 5px 5px 0px 0px #9edab3;
    padding: 10px;
  }

  .boxwrap.smalltwo {
    box-shadow: 5px 5px 0px 0px #b9c7dd;
    padding: 10px;
  }

  .convert-tableTitle .col {
    padding-left: 8px;
    padding-right: 8px;
    position: relative;
    padding-bottom: 25px;
  }

  .converttabs {
    padding: 10px 15px 15px;
  }

  ul.nav.nav-tabs li.nav-item:first-child a.nav-link {
    padding-left: 0px;
  }

  .converttabs .divider-left {
    padding-left: 15px;
    padding-right: 15px;
    border-left: none;
  }

  span.textadvance {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.161641px;
    bottom: 1px;
  }

  .advance-btn {
    margin-bottom: 10px;
  }

  .converttabs label {
    font-size: 9px;
    line-height: 11px;
  }

  .table-Responsive.LpToken table.tokentable.table {
    width: 650px;
  }

  p.copyright {
    text-align: center;
  }

  .footer-inner-block {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(155, 162, 172, 0.2);
  }

  .footer-inner-block.phone,
  .footer-inner-block.LogoWrp {
    border-bottom: none;
  }

  .footer-inner-block h3 {
    margin-bottom: 20px;
  }

  .toggleWrap {
    width: 1050px;
  }

  .cliamBlockOne {
    border: 3px solid transparent;
    box-shadow: none;
    background: transparent;
  }

  .boxwrap img.dollorCoin {
    right: -35px;
    bottom: -35px;
    width: 110px;
  }

  .boxwrap img.cardCoin {
    right: -27px;
    bottom: -20px;
    width: 90px;
  }

  .contain {
    padding-left: 24px;
    margin-right: 10px;
  }

  .autoText {
    margin: 50px auto 20px auto;
  }

  .currentLock {
    margin-right: 12px;
  }

  .delegateBox .convert-tableTitle .col {
    padding-bottom: 0px;
  }

  .InnerLock .tab-pane .convert-tableTitle {
    padding: 5px 15px;
  }
}

@media only screen and (max-width: 479px) {
  table.tokentable.table {
    max-width: 750px;
    width: 720px;
  }
}

/**rishav**/
.sidenav {
  height: calc(100vh);

  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 10px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  display: block;
  transition: 0.3s;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: -0.24px;
  color: #8d99ab;
}

.mobileHeader span {
  color: #fff;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav button.closebtn.btn.btn-secondary {
  background: transparent;
  border: none;
  right: auto;
  left: 0;
  margin-left: 20px;
  font-size: 36px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

.custom-modal-style {
  width: 897px !important;
  height: 802px !important;
}

/***end**/
.spinner-border {
  animation: 1s linear infinite spinner-border !important;
}

.new-pool-tag {
  margin-left: 10px;
  padding: 0 5px;
  border-radius: 20px;
  color: var(--bs-color-link);
  border: 2px solid var(--bs-color-splitter);
}

.old-pool-tag {
  margin-left: 10px;
  padding: 0 5px;
  border-radius: 20px;
  color: var(--bs-color-splitter);
  border: 2px solid var(--bs-color-splitter);
}

.deprecated-pool-tag {
  margin-left: 10px;
  padding: 2px 5px;
  vertical-align: bottom;
  border-radius: 10px;
  color: white;
  border: 3px solid white;
  font-weight: normal;
  font-size: 20px;
}
