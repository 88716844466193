
.advance-btn .switch {
	position: relative;
	display: inline-block;
	width: 38px;
	height: 12px;
	font-style: normal;
	font-weight: 700;
	font-size: 11px;
	line-height: 13px;
}

span.textadvance {
	position: relative;
	bottom: 0px;
	padding-left: 8px;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: -0.24px;
}
.Dark span.textadvance {
	color: white;
}
.advance-btn .switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.advance-btn .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color:#9BA2AC;
	-webkit-transition: .4s;
	transition: .4s;
	border-radius: 3px;
}

.advance-btn .slider:before {
	position: absolute;
	content: "";
	height: 22px;
	width: 22px;
	left: -1px;
	bottom: -5px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
	border: 3px solid #9BA2AC;
	border-radius: 3px;
}

.advance-btn input:checked + .slider {
	background-color: #2196F3;
}

.advance-btn input:focus + .slider {
	box-shadow: 0 0 1px #2196F3;
}

.advance-btn input:checked + .slider:before {
	-webkit-transform: translateX(18px);
	-ms-transform: translateX(18px);
	transform: translateX(18px);
}

/* Rounded sliders */
.advance-btn .slider.round {
	border-radius: 34px;
}

.advance-btn .slider.round:before {
	border-radius: 50%;
}